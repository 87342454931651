import React, { useState } from 'react';
import CCLButton from '../../components/cclButton';
import longArrow from '../../images/long_arrow.svg';
import './trendReport.scss';

const HomeTrendReport = (props) => {
  const { items } = props;
  return (
    <div className="trend-report-wrapper" id="ccl-trends">
      <div className="trend-report">
        <div className="section-title">
          <h1>HOW WILL YOU THRIVE</h1>
          <h1>IN THE FACE OF DISRUPTION?</h1>
        </div>
        <div className="item-list">
          {items ? items.map(item => 
            <div key={item.title} className="item">
              <h4>{item.title}</h4>
              <a href={item.link} className="item-btn" target="_blank" rel="noopener">
                <span>{item.btnText}</span>
                <img src={longArrow} alt=" "/>
              </a>
            </div>
          ) : null}
        </div>
      </div>

    </div>
  );
};

export default HomeTrendReport;
